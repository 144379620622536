import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { Typography, Breadcrumbs } from '@mui/material';
import CompanyInformation from './CompanyInformation';
import CustomButton from '../../shared/CustomButton';

const CustomersCreate = () => {
  const navigate = useNavigate();
  const translate = useTranslate();

  
  return (
    <>
      <Breadcrumbs aria-label='breadcrumb' sx={{ pl: 3, mb: 1 }}>
        <CustomButton
          variant='link'
          title={translate('resources.customer.customers')}
          handleClick={() => navigate('/customers/list')}
        />
        <CustomButton
          variant='link'
          title={translate('resources.customer.add_new_customer')}
        />
      </Breadcrumbs>
      <Typography variant='h4' sx={{ paddingLeft: 3 }}>
        {translate('resources.customer.add_customer')}
      </Typography>
      <CompanyInformation sx={{ py: '1rem' }} />
    </>
  );
};

export default CustomersCreate;
